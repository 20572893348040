import React , { Component } from 'react'
import styled from 'styled-components'
import Banner from '../img/RachelCookFoundation/RachelCookFoundation_LogoHoriz_color.png'
import DonateNow from '../components/button'

export default class Header extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){
        return(
            <HeaderDiv>
                {/* <button style={{visibility: 'hidden'}}>Donate Now</button> */}
                <img src={Banner} alt="Rachel Cook Foundation Banner" />
                <div className="one">
                    <DonateNow />
                </div>
            </HeaderDiv>
        )
    }
}

const HeaderDiv = styled.div`
    /* border: 1px solid red; */
    box-sizing: border-box;
    /* padding-top: 5px; */
    /* border: 1px solid red; */
    max-width: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    color: white;
    font-size: 35px;
    height: 20vh;
    max-height: 20vh;
    position: relative;
    @media(max-width: 800px){
        flex-direction: column-reverse;
        align-items: flex-end;
        justify-content: space-around;
    }
    .one{
        z-index: 2;
        /* border: 1px solid green; */
        position: absolute;
        @media(max-width: 800px){
            position: relative;
        }
    }
    /* font-family: 'Schoolbell', cursive; */
    /* background-image: url("https://new.graphicpanic.com/wp-content/uploads/2015/09/green-chalkboard-background-powerpoint.jpg"); */
    img {
        box-sizing: border-box;
        /* border: 1px solid red; */
        max-height: 99%;
        height: 0 auto;
        width: 100%;
        margin: 0;
        @media(max-width: 800px){
            height: 50%;
        }
        /* width: 100%; */
    }
    /* button {
        @media(max-width: 800px){
            width: 25%;
            max-width: 100px;
        }
        padding: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    } */
`

// .nav {
//     border: 1px solid blue;
//     display: flex;
//     width: 50%;
//     justify-content: space-between;
//   }