import React , { Component } from 'react'
import styled from 'styled-components'
import { oneDiv } from '../styles/styles.js'
import DonateNow from '../components/button'

export default class DonatePage extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){
        return(
            <DonatePageDiv> 
                <div className="one">
                    <h3>Your Donation</h3>
                    <p>The Rachel A. Cook foundation is geared to support special educators and their path to becoming special educators. This includes high school seniors, undergraduate students, and graduate students that are committed to education. Donations that are made will be put towards the scholarship fund to help students become special educators. </p>
                </div>
                <div className="one">
                    <DonateNow />         
                </div>
            </DonatePageDiv>
        )
    }
}

const DonatePageDiv = styled.div`
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .one{
        /* border: 1px solid red; */
        ${oneDiv()}
        width: 95%;
    }
`