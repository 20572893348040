import React , { Component } from 'react'
import styled from 'styled-components'
import { oneDiv } from '../styles/styles.js'

export default class ContactPage extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){
        return(
            <ContactPageDiv> 
                <h1>Contact Us</h1>
                <div className="bottom">
                    <div className="one">
                        <h4>Board Members</h4>
                        <ul>
                            <li>President – Brian Cook</li>
                            <li>Vice-President – Vicki Price</li>
                            <li>Treasurer – Emily Wilson</li>
                            <li>Secretary – Kathy Casaus</li>
                            <li>Director – Linda Elliott</li>
                            <li>Director – Robin Goodwin</li>
                            <li>Member At Large – Riley Cook</li>
                            <li>Member At Large – Makenna Cook</li>
                            <li>Web Developer - Michael Kerbleski</li>
                        </ul>
                    </div>
                    <div className="one">
                        <strong><p>Email:</p></strong>
                            <li>brian@rachelacook.org</li>
                        <strong><p>PO Box:</p></strong>
                            <li>Rachel A. Cook Foundation</li>
                            <li>P.O. Box 92374</li>
                            <li>Albuquerque, NM 87199</li>
                    </div>
                </div>
            </ContactPageDiv>
        )
    }
}

const ContactPageDiv = styled.div`
    /* border: 1px solid red; */
    padding: 20px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 95%;
    @media(max-width: 800px){
        align-items: center;
    }
    .bottom{
        width: 90%;
        display: flex;
        flex-direction: row;
        @media(max-width: 800px){
            flex-direction: column;
            align-items: center;
        }
        .one {
            ${oneDiv()}
            align-items: flex-start;
            list-style: none;
            width: 45%;
            @media(max-width: 800px){
                width: 80%
            }
            strong{
                width: 100%;
            }
        }
    }
`