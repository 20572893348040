import React , { Component } from 'react'
import styled from 'styled-components'
import { oneDiv } from '../styles/styles.js'

export default class ScholarshipsPage extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){
        return(
            <ScholarshipsPageDiv>
                <div className="one">
                    <h3>Scholarships</h3>
                    <p>Current or returning students can apply for this scholarship to help them pay for their education. We encourage everyone, no matter where they are at in their education process to apply. Students who are interested in becoming special education or general education teachers and want to do more for their community should especially apply. If you are granted the scholarship, then you will be given 1,000 dollars to use towards your educational path. </p>
                </div> 
                <div className="scholarshipBTNs">
                    <a target="_blank" href="https://www.dropbox.com/s/bppzsnl9wrof40p/Rachel%20A%20Cook%20Foundation%20Scholarship%20Application_V2.pdf?dl=0" download >APPLY NOW!</a>
                </div>
            </ScholarshipsPageDiv>
        )
    }
}

const ScholarshipsPageDiv = styled.div`
    /* border: 1px solid red; */
    padding: 20px;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .scholarshipBTNs{
        /* border: 1px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
    
    }
    .one{
        
        ${oneDiv()}
        width: 90%;
    }
    a {
        /* border: 1px solid black;
        border-radius: 25px; */
    }
`