import React , { Component } from 'react'
import { oneDiv } from '../styles/styles.js'
import styled from 'styled-components'
import fish from '../../src/img/fish.JPG'
import classroom from '../../src/img/classroom.JPG'

export default class AboutPage extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){
        return(
            <AboutPageDiv> 
                {/* <div className="top">
                    img
                </div> */}
                <div className="bottom">
                    <div className="row" id="fish">
                        <div className="one" >
                            <h4>Rachel's Legacy</h4>
                            <p>Rachel Cook was a well known and loved teacher who taught in Albuquerque, New Mexico. She dedicated herself to making sure every student was treated equally, despite their challenges. Rachel taught a special education class at North Star Elementary School and was extremely dedicated to all of her students and the work she did to improve the community. Rachel passed away suddenly in the beginning of 2018 and left her entire community in shock. Moved by her dedication to teach, Brian Cook, Rachel’s husband, created a non-profit organization in her name. With the help of her family and friends, the Rachel A. Cook Foundation was formed to inspire future educators. Rachel will be forever remembered in her community for pouring her love and spirit into teaching. 
                            </p>
                        </div>
                        <img src={fish} alt='fish bulletin board'  />
                    </div>
                    <div className="row" id="chairs">
                        <img style={{maxHeight: '450px'}} alt='classroom' src={classroom}  />
                        <div className="one">
                            <h4>Rachel's Passion</h4>
                            <p>Even as a young girl Rachel took pride in helping others, whether it was her friends, family, or classmates. After college, Rachel became a cardiac rehabilitation tech as she always had a passion to help others. Rachel went back to school to become a special education teacher. She graduated, got a job, and fell in love with her work. Rachel always loved being able to make a student’s life better and that is what inspired people around her.</p>
                        </div>
                    </div>
                </div>
            </AboutPageDiv>
        )
    }
}

const AboutPageDiv = styled.div`
    /* border: 1px solid red; */
    /* padding: 15px; */
    .top {
        /* border: 1px solid green; */
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
    }
    .bottom {
        display: flex;
        flex-direction: column;
        .row{
            /* border: 1px solid green; */
            /* background: blue; */
            .one {
                /* ${oneDiv()} */
                img{
                    max-height: 350px;
                }
            }
        }
        #fish{
            /* border: 1px solid pink; */
            @media(max-width: 800px){
                /* background: orange; */
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
        #chairs{
            /* border: 1px solid pink; */
            @media(max-width: 800px){
                /* background: orange; */
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .top {
        background: gray;
    }
`