export function oneDiv(){
    return `
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 25px;
        padding: 10px;
        width: 100%;
        max-width: 100%;
        h4{
            margin: 0;
        }
        p {
            max-width: 90%;
        }
    `
}