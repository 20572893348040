import React , { Component } from 'react'
import styled from 'styled-components'

export default class DonateNow extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){
        return(
            <DonateNowDiv> 
                 <a 
                        href="javascript:void(0)" 
                        id="146430D4-FDFC-47C1-B3F6-5A325EB4E5CB.75990fd0-9a94-4f82-ae53-a43a8b21e11a" 
                        class="fg-donation-button-4ccb04fb46c30" 
                        onClick={() => {
                            window.open('https://donate.firstgiving.com/secure/donate/146430D4-FDFC-47C1-B3F6-5A325EB4E5CB/75990fd0-9a94-4f82-ae53-a43a8b21e11a')
                            }}
                    >Donate Now</a> 
            </DonateNowDiv>
        )
    }
}

const DonateNowDiv = styled.div`
    /* border: 1px solid red; */
    a{
        text-indent: -999999px;
        background: url('https://donate.firstgiving.com/dpa/static/img/donate_button_blue.png') no-repeat; 
        width: 188px; 
        height: 44px; 
        display: block;
    }
`