import React , { Component } from 'react'
import styled from 'styled-components'

export default class Footer extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){
        return(
            <FooterDiv> 
                <p>@ Copyright 2019</p>
            </FooterDiv>
        )
    }
}

const FooterDiv = styled.div`
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    box-sizing: border-box;
    background: rgba(255,255,255,0.7);
`