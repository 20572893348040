import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import Route from 'react-router-dom/Route';
import { BrowserRouter as Router} from 'react-router-dom';

ReactDOM.render( 
    <Router>
        <Route path='/' render={() => {
        return <App />
        }}></Route>
    </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
