import React , { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

export default class Nav extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){
        return(
            <NavDiv> 
                <Link className="item" to='/'>Home</Link>
                <Link className="item" to='/about'>About</Link>
                <Link className="item" to='/events'>Events</Link>
                <Link className="item" to='/donate'>Donate</Link>
                <Link className="item" to='/scholarships'>Scholarships</Link>
                <Link className="item" to='/contact'>Contact</Link>
            </NavDiv>
        )
    }
}

const NavDiv = styled.div`
border: 2px solid pink;
margin: 3px;
/* background: green; */
    border: 1px solid gray;
    margin: 1px;
    justify-content: space-between;
    display: flex;
    min-height: 30px;
    /* margin: 1px; */
    max-width: 100%;
    /* width: 900px; */
    @media(max-width: 800px){
        flex-direction: column;
    }
    .item{
        border: .5px solid lightgray;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-family: 'Overpass', sans-serif;
        /* box-sizing: border-box; */
        &:hover{
            text-decoration: underline;
            background: rgb(145,199,63);
            color: white;
        }
    }
    
`