import React , { Component } from 'react'
import styled from 'styled-components'

export default class EventsPage extends Component {
    constructor(props){
        super(props)
        this.state = {}
    }

    render(){
        return(
            <EventsPageDiv> 
                <h4>Rachel's Walk to Inspire - April 27, 2019</h4>
                <p>We want to thank all of those who helped us break a sweat in Rachel’s honor to support Special Educators! We could not have done any of this without the support of our family, friends, and the community at large.</p>
                <p>If you are interested in buying a t-shirt – 
                <strong><a target="_blank" href="https://www.firstgiving.com/event/415721/T-SHIRTS-FOR-SALE">Click Here</a></strong></p>
                <iframe src="https://player.vimeo.com/video/333874831" width="100%" height="480" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                {/* <div className="eventsBottom">
                    <div className="col"><button onClick={() => window.open('https://www.firstgiving.com/event/415721/Rachel-Walk-to-Inspire')}>Sign Up</button></div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3260.562234649407!2d-106.54696948475454!3d35.19246198031128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872275fafafe68b1%3A0xc1792adbcac76cdf!2sNorth+Star+Elementary+School!5e0!3m2!1sen!2sus!4v1553445293762"></iframe>
                </div> */}
            </EventsPageDiv>
        )
    }
}

const EventsPageDiv = styled.div`
    /* border: 1px solid red; */
    padding: 20px;
    box-sizing: border-box;
    max-width: 100%;
    width: 95%;
    padding: 10px;
    margin: 5px;
    p {
        /* border: 1px solid green; */
        margin-left: 40px;
        width: 90%;
    }
    li {
        /* border: 1px solid blue; */
        width: 70%;
    }
    
    @media(max-width: 800px){
        /* flex-direction: column; */
        p {
            /* border: 1px solid pink; */
            width: 80%;
        }
    }
    @media(max-width: 550px){
        /* flex-direction: column; */
        p {
            /* border: 1px solid pink; */
            margin-left: 0;
        }
    }
    button{
        padding: 5px;
        font-size: 20px;
    }
    .eventsBottom{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        iframe{
            width: 60%;
            height: 100%;
            margin: 8px;
        }
        @media(max-width: 550px){
            flex-direction: column;
            iframe{
                width: 100%;
            }
        }
    }
`