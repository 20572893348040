import React, { Component } from 'react';
import './App.css';
// import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom'
import DonatePage from './Pages/DonatePage'
import EventsPage from './Pages/EventsPage'
import ScholarshipPage from './Pages/ScholarshipsPage'
import AboutPage from './Pages/AboutPage'
import ContactPage from './Pages/ContactPage'
import Header from './components/header'
import Nav from './components/nav'
import Footer from './components/footer'
import { oneDiv } from './styles/styles.js'
import styled from 'styled-components'
import NarrowChalk from './img/IMG_0813.jpeg'
import WideChalk from './img/IMG_0824.jpeg'
import Shirt from './img/t-shirt.jpg'

class App extends Component {
  render() {
    return (
      <AppDiv>
        <Header />
        <div className="body">
          <Nav />
          <Route exact path='/' render={() => {
            return (
              <div>
                <div className="banner">
                  {/* <div className="col"><button style={{visibility: 'hidden'}}>Sign Up</button></div> */}
                  <img src={Shirt} />
                  <div className="col">
                    <h1>T-SHIRTS ARE STILL ON SALE!</h1>
                    <h2><i>20$ Adult - 10$ Children</i></h2>
                    <div className="col"><button onClick={() => window.open('https://www.firstgiving.com/event/415721/T-SHIRTS-FOR-SALE')}>Click Here</button></div>
                  </div>
                </div>
                <div className="row" id="sse">
                  <div className="one" >
                    <h4>Supporting Special Educators</h4>
                    <p>The Rachel A. Cook Foundation is a 501(c)3  non-profit charity that is looking to promote special educators. Special educators are those who go above and beyond the average requirements of being a teacher. Rachel A. Cook was a special educator. This scholarship is to honor her devotion to teaching.
                    </p>
                  </div>
                    <img src={WideChalk} />
                </div>
                <div className="row" id="ourMission">
                  <div className="one">
                    <h4>Our Mission</h4>
                    <p>Our mission is to support and promote the growth of special educators. Our scholarship will help with the educational process of teachers. Fundraising will go to the students who plan to become and who are committed to becoming a special educator. This includes high school seniors, undergraduate students, and graduate students. 
                    </p>
                  </div>
                    <img style={{margin: '20px'}} src={NarrowChalk} />
                </div>
              </div>)
          }} />
          <Route path='/about' component={AboutPage}></Route>
          <Route path='/events' component={EventsPage}></Route>
          <Route path='/donate' component={DonatePage}></Route>
          <Route path='/scholarships' component={ScholarshipPage}></Route>
          <Route path='/contact' component={ContactPage}></Route>
          {/* <Route path='/download' component={Application}></Route> */}
        </div>
          <Footer />
      </AppDiv>
    );
  }
}

export default App;

const AppDiv = styled.div`
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
  /* width: 100vw; */
  /* height: 100vh; */
  background-image: url("https://texturefabrik.files.wordpress.com/2013/05/29-05-13_paper07.jpg"); 
  .body{
    min-height: 75vh;
    opacity: 1;
    
    /* border: 1px solid green; */
    /* max-width: 900px; */
    /* padding: 5px; */
    background: rgba(255,255,255,0.3);
    /* min-height: 85vh; */
    height: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    max-width: 900px;
    overflow: hidden;
    p, li {
      font-size: 18px;
      /* width: 100vw; */
    }
    .banner {
      background: rgba(48, 138, 171, 0.3);
      border: 2px solid black;
      margin: 0;
      display: flex;
      padding: 20px;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      h1{
        margin-bottom: 0
      }
      i {
        color: rgb(30,30,30);
      }
      img{
        max-height: 150px;
      }
      @media(max-width: 800px){
        flex-direction: column;
      }
      .col {
        /* border: 1px solid red; */
        text-align: center;
        margin: 5px;
        button {
          font-size: 20px;
          /* background: blue; */
          margin-bottom: 15px;
        }
      }
      p {
        /* margin: 10px; */
        word-spacing: 3px;
        max-width: 100vw;

      }
      h2 {
        margin: 15px;
        font-weight: lighter;
      }
    }
    #sse{
      /* border: 1px solid blue; */
      flex-direction: column;
      img{
        max-height: 190px;
      }
    }
        img{
          max-height: 300px;
        }
    .row{
      /* border: 1px solid blue; */
      display: flex;
      flex-direction: row;
      margin-bottom: 25px;
      margin-top: 25px;
      /* max-width: 100%; */
      align-items: center;
      width: 100%;
      @media(max-width: 800px){
        width: 100vw;
        flex-direction: column;
      }
      .one{
        /* ${oneDiv()} */
        /* width: 100%; */
        margin: 10px;
        @media(max-width: 800px){
/* padding: 20px; */
/* background: orange; */
        }
      }
      .img{
        background: gray;
        display: flex;
        flex-direction: row; 
        justify-content: center;
        align-items: center;
        color: white
      }
    }
  }
`